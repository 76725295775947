@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.searchPanel {
	margin: 0 auto;
	padding: 0.5rem;
	border-radius: $border-radius-sm;
	background-color: $white;
	color: $body-color;

	// MQs
	@media ($mdUp) {
		padding: 1rem;
		border-radius: $border-radius-lg;
	}
	@media ($lgUp) {
		max-width: 54rem;
		padding: 1.25rem;
	}
	@media ($xlUp) {
		padding: 1.5rem;
	}
}
.searchPanelInputsGroup {
	border-radius: $border-radius-xs;
	background-color: $grey-ultra-light;
	> .searchPanelInputsItem {
		flex: 1 1 0%;
		min-width: 0;
		max-width: 100%;
		border-radius: inherit;
	}
	> .searchPanelInputsItem + .searchPanelInputsItem .searchPanelInputButton {
		&::before {
			left: 0;
			height: 2rem;
			margin-top: -1rem;
			background-color: $grey-medium;
			transition: background-color $transition;
		}

		// HOVERs
		&:hover,
		&:global(.active) {
			&::before {
				background-color: $grey-light;
			}
		}
	}

	// MQs
	@media ($mdUp) {
		border-radius: $border-radius-sm;
	}
}
.searchPanelInputButton {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 0;
	padding: 1rem;
	border-radius: inherit !important; /* stylelint-disable-line */
	text-align: left;
	text-decoration: none;
	> * {
		max-width: 100%;
	}

	// HOVERs
	&:hover,
	&:global(.active) {
		background-color: $grey-light;
		color: inherit;
	}

	// MQs
	@media ($mdDown) {
		padding-top: 1.1875rem;
		padding-bottom: 1.1875rem;
		&::before {
			display: none;
		}
	}
	@media ($mdUp) {
		padding: 1.125rem 1.5rem 1rem;
	}
}
.searchPanelInputButtonLabel {
	min-height: 1.125rem;
}
.searchPanelInput {
	@include text-body();
	padding: 0;
	border: 0;
	border-radius: 0;
	background-color: transparent;
	color: inherit;
	&::placeholder {
		color: inherit;
	}

	&:focus {
		background-color: transparent;
		color: inherit;
	}

	// MQs
	@media ($mdDown) {
		font-weight: $font-weight-bold;
		font-size: 0.8125rem;
		line-height: divide(24, 13);
		letter-spacing: $letter-spacing;
		text-transform: uppercase;
	}
}
.searchPanelButton {
	padding: 0.75rem 0.875rem;

	// MQs
	@media ($mdDown) {
		border-radius: $border-radius-xs;
		&:global(.btn .icon) {
			margin-top: 0;
			margin-bottom: 0;
			font-size: 0.75rem;
		}
	}
	@media ($mdUp) {
		padding: 1.3125rem 1.4375rem;
	}
	@media ($lgUp) {
		padding-right: 1.5625rem;
		padding-left: 1.5625rem;
	}
}
.searchPanelInputDropdown {
	position: absolute;
	top: 100%;
	margin-top: 1rem;
	visibility: hidden;
	opacity: 0;
	transition: opacity $transition, visibility $transition;

	// STATEs
	&--visible {
		visibility: visible;
		opacity: 1;
	}

	// MQs
	@media ($mdDown) {
		right: -4rem;
		left: -0.5rem;
	}
	@media ($mdUp) {
		margin-top: 2.75rem;

		// VARIANTs
		&--left {
			left: -1.5rem;
			width: 23.5rem;
		}
		&--middle,
		&--right {
			left: 0;
		}
		&--middle {
			width: 200%;
		}
		&--right {
			width: calc(100% + 8rem);
		}
	}
}
