@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.ctaBlock {
    display: grid;
    gap: 2.5rem;
    max-width: 82rem;
    margin: 0 auto;

    // VARIANTs
    &--simple {
        row-gap: 1.5rem;
        max-width: 87.5rem;
    }

    // MQs
    @media ($mdUp) {
        grid-template-columns: 1fr 1fr;

        &--single {
            grid-template-columns: 1fr;
            max-width: 40rem;
        }
    }

    @media ($xlUp) {
        // VARIANTs
        &--simple {
            column-gap: 4.5rem;
        }
    }
}
