@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.promoBox {
	display: flex;

	// MQs
	@media ($mdDown) {
		flex-wrap: wrap;
	}
}
.promoBoxContent,
.promoBoxImage {
	// MQs
	@media ($mdDown) {
		border-radius: $border-radius-sm;

		&--half-width {
			&:first-of-type {
				border-radius: $border-radius-sm $border-radius-sm 0 0;
			}
			&:last-of-type {
				border-radius: 0 0 $border-radius-sm $border-radius-sm;
			}
		}
	}

	@media ($mdUp) {
		&--half-width {
			&:first-of-type {
				border-radius: $border-radius-sm 0 0 $border-radius-sm;
			}
			&:last-of-type {
				border-radius: 0 $border-radius-sm $border-radius-sm 0;
			}
		}
	}
}
.promoBoxContent {
	position: relative;
	z-index: 1;
	border-top-right-radius: 0;

	// MQs
	@media ($mdDown) {
		order: 1;
		width: 100%;
		border-top-left-radius: 0;

		&--half-width {
			order: 0;
		}
	}
	@media ($mdUp) {
		flex: 0 0 35rem;
		max-width: 50%;
		border-bottom-right-radius: 0;

		// VARIANTs
		&--dark {
			flex: 0 0 28rem;
		}

		&--half-width {
			order: 0;
			flex: 1 0 50rem;
		}
	}
}
.promoBoxImage {
	border-bottom-left-radius: 0;

	// MQs
	@media ($mdDown) {
		position: relative;
		width: 100%;
		height: 23.125rem;
		border-bottom-right-radius: 0;
		&::after {
			@include image-gradient();
			content: '';
			height: 85%;
		}
		&--half-width {
			order: 0;
		}
	}
	@media ($mdUp) {
		flex: 1 1 0;
		border-top-left-radius: 0;
	}
}
.promoBoxHeading {
	// MQs
	@media ($mdDown) {
		@include text-truncate-multiple(5);
		position: absolute;
		right: 1.5rem;
		bottom: 100%;
		left: 1.5rem;
		z-index: 1;
		color: $white;
	}
	@media ($mdUp) {
		margin-right: -22.5rem;
	}
}
