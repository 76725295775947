@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.newsletterBox {
	padding: 1rem;

	@media ($lgUp) {
		padding: 2rem;
	}
}

.newsletterBoxPortrait {

}
