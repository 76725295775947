@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.overlapHeroIntro {
    position: relative;
    margin-top: -8rem;

    // MQs
    @media ($xlUp) {
        margin-top: -9.5rem;
    }
}
