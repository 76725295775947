@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.infoBox {
	// VARIANTs
	&--transparent .infoBoxText {
		color: $grey-dark;
	}
	&--sep-b {
		// MQs
		@media ($lgUp) {
			border-bottom: 1px solid $grey-medium;
		}
	}
	&--sep-l {
		// MQs
		@media ($lgUp) {
			position: relative;
			&::before {
				content: '';
				position: absolute;
				top: 4rem;
				bottom: 4rem;
				left: 0;
				border-left: 1px solid $grey-medium;
			}
		}
	}

	// MQs
	@media ($xxxlUp) {
		padding: 4rem !important; /* stylelint-disable-line */
	}
}
