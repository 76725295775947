@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.ctaCard {
    border-radius: 0.75rem;
    background-color: $white;
}

.ctaCardHeading {
    line-height: 1.4;

    // VARIANTs
    &--bd {
        border-bottom: 1px solid $grey-ultra-light;
    }
}
