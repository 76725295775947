@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.carouselNav {
	display: inline-block;
	border-radius: $border-radius-xs;
	background-color: $white;

	// MQs
	@media ($xlUp) {
		// VARIANTs
		&--md {
			border-radius: $border-radius-lg;
		}
	}
}
.carouselNavButton {
	padding: 0.5625rem 0.875rem;
	border-color: transparent;
	line-height: 1;
	&:first-child {
		border-radius: $border-radius-xs 0 0 $border-radius-xs;
	}
	&:last-child {
		border-radius: 0 $border-radius-xs $border-radius-xs 0;
	}

	// MQs
	@media ($xlDown) {
		:global(.icon) {
			font-size: (1rem * divide(20, 24));
		}
	}
	@media ($xlUp) {
		// VARIANTs
		.carouselNav--md & {
			padding: 1.6875rem 2.0625rem;
			&:first-child {
				border-radius: $border-radius-lg 0 0 $border-radius-lg;
			}
			&:last-child {
				border-radius: 0 $border-radius-lg $border-radius-lg 0;
			}
		}
	}
}
