@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.promoCardLarge,
.promoCardLargeHolder {
    // VARIANTs
    &--green,
    &--blue {
        color: $white;
    }
}
.promoCardLarge {
    position: relative;
}
.promoCardLargeHolder {
    // MQs
    @media ($mdUp) {
        $holder-columns-width: 10;
        $content-columns-width: 5;
        display: flex;
        .promoCardLarge {
            flex: 0 0 auto;
            width: calc(
                (#{divide($grid-columns, $holder-columns-width)} * (100% + var(--bs-gutter-x))) * #{divide(
                        $content-columns-width,
                        $grid-columns
                    )} - var(--bs-gutter-x)
            );
        }
    }
}
.promoCardLargeContent {
    flex-grow: 1;
}
.promoCardLargeMainHeading {
    flex-grow: 1;

    // MQs
    @media ($mdDown) {
        + .promoCardLarge {
            padding-top: 1.25rem !important; /* stylelint-disable-line */
        }
    }
    @media ($lgUp) {
        + .promoCardLarge {
            padding-left: 0 !important; /* stylelint-disable-line */
        }
    }
}
.promoCardLargeDesc {
    // MQs
    @media ($lgDown) {
        font-size: $link-perex-font-size !important; /* stylelint-disable-line */
        line-height: $link-perex-line-height !important; /* stylelint-disable-line */
    }
}
.promoCardLargeButton {
    border-top: 1px solid $grey-light;
    :global(.btn) {
        @extend %link-mask;

        // VARIANTs
        .promoCardLarge--disable-link-mask &::before {
            display: none;
        }
    }

    // VARIANTs
    .promoCardLarge--green &,
    .promoCardLarge--blue & {
        border-top-color: rgba($white, 0.5);
        :global(.btn) {
            color: inherit;
        }

        // HOVERs
        :global(.btn:hover) {
            text-decoration: underline;
        }
    }

    // VARIANTs
    .promoCard--disable-link-mask &::before {
        display: none;
    }
}
