@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.searchPanelSidebar {
	overflow-y: auto;
}
.searchPanelSidebarBody {
	margin-top: -$border-radius-xl;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	overflow-y: auto;
}
.searchPanelSidebarBodyHeader {
	top: -2rem;
	margin-top: -2rem;
}
