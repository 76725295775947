@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.inspirationCarousel {
	overflow: hidden;
}
.inspirationCarouselHolder,
.inspirationCarouselViewport {
	position: relative;
}
.inspirationCarouselContainer {
	--bs-gutter-x: 1rem;
	display: flex;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;

	// MQs
	@media ($mdDown) {
		@include scrollbar();
		@include scrollbarHorizontal();
	}
	@media ($mdUp) and ($xlDown) {
		margin-right: calc(var(--bs-gutter-x) * -0.5);
		margin-left: calc(var(--bs-gutter-x) * -0.5);
	}
}
.inspirationCarouselSlide {
	flex: 0 0 auto;
	width: percentage(divide(11, 12));

	// MQs
	@media ($xlDown) {
		padding-right: calc(var(--bs-gutter-x) * 0.5);
		padding-left: calc(var(--bs-gutter-x) * 0.5);
	}
	@media ($mdUp) {
		width: 40%;
	}
	@media ($xlUp) {
		+ .inspirationCarouselSlide {
			margin-left: -1px;
		}

		// VARIANTs
		&:nth-child(odd) {
			padding-top: 5rem;
		}
		&:nth-child(even) {
			padding-bottom: 5rem;
		}
	}
	@media ($xxlUp) {
		width: 30%;
	}
}
.inspirationCarouselSlidePlaceholder {
    display: none;

    @media ($lgUp) {
        display: initial;
    }
}
.inspirationCarouselNav {
	position: absolute;
	z-index: 1;

	// MQs
	@media ($lgDown) {
		right: 0;
		bottom: 0;
	}
	@media ($lgUp) {
		top: 40%;
		left: 40%;
		transform: translateX(-50%);
	}
	@media ($xxlUp) {
		left: 30%;
	}
	@media ($xxxlUp) {
		top: 30%;
	}
}
.inspirationCarouselSideContent {
	// MQs
	@media ($lgUp) {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		width: 40%;
		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			z-index: -1;
			pointer-events: none;
		}
		&::before {
			right: 100%;
			width: 100vw;
			background-color: $body-bg;
		}
		&::after {
			width: 100%;
			background-image: linear-gradient(to right, $body-bg, rgba($body-bg, 0)), linear-gradient(to left, rgba(#f5f0f0, 0), #f5f0f0);
			background-repeat: no-repeat;
			background-size: 100% 100%, percentage(divide(416, 528)) 100%;
		}
	}
	@media ($xxlUp) {
		width: 30%;
	}
}
.inspirationCarouselCount {
	position: absolute;
	top: 40%;
	left: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	min-height: 5rem;

	// MQs
	@media ($xxxlUp) {
		top: 30%;
	}
}
