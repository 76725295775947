@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.tag {
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 0.375rem 0.9375rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    > a {
        @extend %link-mask;
        color: inherit;
        &:hover {
            text-decoration: underline;
        }
    }

    // VARIANTs
    &--rounded {
        border-radius: $border-radius-sm;
    }
    &--green {
        background-color: $emerald-green;
        color: $white;
    }
    &--green-light {
        background: $green-light;
        color: $emerald-green;
    }
    &--red {
        background-color: $primary;
        color: $white;
    }
    &--outline {
        border-color: $grey-dark;
    }
    &--outline-medium {
        border-color: $grey-medium;
    }
    &--dark {
        background-color: $anthracite-grey;
        color: $white;
    }
    &--white {
        background-color: $white;
    }
    &--brown {
        background-color: $brown;
        color: $white;
    }
    &--orange {
        background-color: orange;
        color: $white;
    }
    &--blue {
        background-color: $blue-light;
    }
    &--light-grey {
        background-color: $grey-light;
    }
    &--xs,
    &--sm {
        font-weight: $font-weight-semibold;
        font-size: 0.75rem;
        line-height: divide(16, 12);
        letter-spacing: $letter-spacing;
        text-transform: uppercase;
    }
    &--xs#{&}--rounded,
    &--sm#{&}--rounded {
        border-radius: $border-radius-xs;
    }
    &--xs {
        padding: 0.1875rem 0.375rem;
    }
    &--sm {
        padding: 0.4375rem 0.6875rem;
    }
}

.tag_icon {
    margin-top: -1px;
    margin-bottom: -2px;
}

.ctag {
    margin-right: -0.25rem;
    color: $grey-dark;
    cursor: pointer;

    :hover {
        color: #ffffff;
    }
}

.bg_orange {
    background-color: orange;
}

.bg_purple {
    background-color: purple;
}

.bg_green {
    background-color: $emerald-green;
}

.bg_white {
    background-color: $blue;
}

.bg_color_1 {
    background-color: #ef7db1;
}

.bg_color_2 {
    background-color: #994e0d;
}

.bg_color_3 {
    background-color: #053b4d;
}

.bg_color_4 {
    background-color: #600016;
}

.bg_color_5 {
    background-color: #15adef;
}

.bg_red {
    background-color: $red;
}
