@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.numbersList {
    display: grid;
    gap: 1.5rem;
    padding: 0;
    list-style-type: none;

    // MQs
    @media ($mdUp) {
        &--4 {
            grid-template-columns: repeat(4, 1fr);
        }
        &--3 {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

.numbersListItem {
    row-gap: 0.5rem;
    text-align: center;
}

.numbersListItemValue {
    // VARIANTs
    &--sm {
        position: relative;
        line-height: 1.4;
        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            width: 129px;
            max-width: 100%;
            // background-image: url('./number-list-value-pattern.svg');
            // background-repeat: no-repeat;
            // background-size: cover;
            transform: translate(-50%, -50%);
            aspect-ratio: 129 / 13;
        }
    }
    &--md {
        //
    }

    // MQs
    @media ($xlDown) {
        // VARIANTs
        &--md {
            font-size: 2.5rem;
            line-height: 1.2;
        }
    }

    @media ($xlUp) {
        //    VARIANTs
        &--md {
            line-height: 1.5;
        }
    }
}

.numbersListItemLabel {
    // VARIANTs
    &--sm {
        //
    }
    &--md {
        line-height: 1.4;
    }

    // MQs
    @media ($lgDown) {
        // VARIANTs
        &--md {
            font-size: 1.375rem;
        }
    }
}
