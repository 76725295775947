@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.inspirationCard {
	position: relative;
	padding: 0;

	// MQs
	@media ($xlUp) {
		padding: 1.0625rem;
		border: 1px solid transparent;
		transition: border-color $transition;

		// HOVERs
		&:hover {
			border-color: $emerald-green;
		}
	}
}
.inspirationCardImage {
	position: relative;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	&::before {
		content: '';
		display: block;
		padding-top: percentage((divide(3, 4)));
	}

	// MQs
	@media ($mdDown) {
		border-top-left-radius: $border-radius-sm;
		border-top-right-radius: $border-radius-sm;
	}
}
.inspirationCardHeading {
	a {
		@extend %link-mask;
		color: inherit;
	}
}
